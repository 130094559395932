import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Currency";
const currencyService = {
  get({ pageSize, pageIndex }) {
    const newResource = `${resource}?pageSize=${pageSize}&pageIndex=${pageIndex}`;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(newResource, "");
    }
  },

  getByCode(code) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, code);
    }
  },
  getBaseCurrency() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource + "/BaseCurrency", "");
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(code, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, code, data);
    }
  },

  delete(code, isActive) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(
        `${resource}/${code}?isActive=${isActive}`,
        code
      );
    }
  },
};

export default currencyService;
