<template>
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <form @submit="saveForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form__item">
              <label class="form__label">Institution Name</label>
              <input
                class="input form__input"
                v-model="orgDetails.organisationName"
                placeholder="Specify the name of your institution"
                v-bind:class="{ 'input-required': orgErr.organisationName }"
              />
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Institution Address</label>
              <input
                class="input form__input"
                placeholder="Enter your address"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">City</label>
              <input
                class="input form__input w-100"
                placeholder="Enter your city"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">State / Province / Region</label>
              <input
                class="input form__input w-100"
                placeholder="Enter institute state"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">ZIP / Postal code</label>
              <input
                class="input form__input w-100"
                placeholder="Enter your zip code"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Country</label>
              <input
                class="input form__input w-100"
                placeholder="Enter your country"
              />
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Mobile Phone</label>
              <input
                class="input form__input w-100"
                v-model="orgDetails.organisationPhoneNo"
                placeholder="Enter institute phone"
                v-bind:class="{
                  'input-required': orgErr.organisationPhoneNo,
                }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Email Address</label>
              <input
                class="input form__input w-100"
                v-model="orgDetails.organisationEmailAddress"
                placeholder="Enter institute email address"
                v-bind:class="{
                  'input-required': orgErr.organisationEmailAddress,
                }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="input form__label">Local Date Format</label>
              <input
                class="input form__input w-100"
                placeholder="DDD - MM - YY"
                v-model="orgDetails.localDateFormat"
                v-bind:class="{ 'input-required': orgErr.localDateFormat }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="input form__label">Local Time Date Format</label>
              <input
                class="input form__input w-100"
                v-model="orgDetails.localDateTimeFormat"
                placeholder="DDD - MM  -  YYY  HH:SS"
                v-bind:class="{
                  'input-required': orgErr.localDateTimeFormat,
                }"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <label class="form__label">Decimal Mark</label>
              <select
                class="select--lg"
                v-bind:class="{ 'input-required': orgErr.decimalMark }"
                v-model="orgDetails.decimalMark"
              >
                <option value=".">Period (.)</option>
                <option value=",">Comma (,)</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__item">
              <div class="form__extra">
                <div class="row">
                  <div class="col-6">
                    <p class="form__extra__text">Currency</p>
                    <p class="form__extra__text form__extra__text--green">
                      {{ baseCurrency.currencyName }} ({{
                        baseCurrency.currencyCode
                      }})
                    </p>
                  </div>
                  <!-- <div class="col-6">
                    <p class="form__extra__text">Time Zone</p>
                    <p class="form__extra__text form__extra__text--green">
                      {{ orgDetails.timezone }}
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__item">
          <div class="form__action">
            <button
              class="button form__button form__button--lg"
              v-if="!isSaving && userCanCreateOrgDeteails"
              @click="saveForm"
            >
              Save Changes
            </button>
            <!-- loader button -->
            <a
              role="button"
              class="button form__button form__button--lg w-25 save-changes"
              v-if="isSaving"
            >
              <div class="spinner-border text-light" role="status"></div>
            </a>
            <a
              role="button"
              class="form__action__text form__action__text--danger text--capital ml6"
              @click="goToAdministration"
            >
              cancel
            </a>
          </div>
        </div>
      </form>
    </div>
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import organisationDetailsService from "@/core/services/general-setup-service/organisation-details.service";
import currencyService from "@/core/services/general-setup-service/currency.service";
export default Vue.extend({
  name: "organization-details",
  data: function () {
    return {
      content_header: {
        title: "Organization Details",
        subTitle: "",
        // subTitle:
        //   "Set up Prolean with all the details for your organization. Define your institution details and contact information.",
      },
      isLoading: false,
      isSaving: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "Organisation details updated.",
      },
      baseCurrency: {
        currencyCode: null,
        currencyName: null,
      },
      orgDetails: {
        organisationName: null,
        organisationEmailAddress: null,
        organisationPhoneNo: null,
        localDateFormat: null,
        localDateTimeFormat: null,
        decimalMark: null,
        timezone: null,
      },
      orgErr: {
        organisationName: false,
        organisationEmailAddress: false,
        organisationPhoneNo: false,
        localDateFormat: false,
        localDateTimeFormat: false,
        decimalMark: false,
      },
    };
  },
  methods: {
    async getOrgDetails() {
      try {
        this.isLoading = true;
        const res = await organisationDetailsService.get();
        if (res.data.data != null) {
          this.orgDetails = res.data.data;
          this.defaultOrgDetails = this.orgDetails;
        }
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCurrency() {
      try {
        this.isLoading = true;
        const res = await currencyService.getBaseCurrency();
        if (res.data.data != null) {
          this.baseCurrency = res.data.data;
        }
        this.isLoading = false;
      } catch (e) {
        const error = e?.response?.data?.message;
        if (error) this.alertError(error);
      }
    },
    goToAdministration() {
      this.$router.push({ name: "administration-menu" });
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await organisationDetailsService.update(this.orgDetails);
          this.alertSuccess("Organization details updated.");
          this.isSaving = false;
          this.getOrgDetails();
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    validateForm() {
      this.clearOrgErr();
      if (!this.orgDetails.organisationName) {
        this.orgErr.organisationName = true;
      }
      if (!this.orgDetails.organisationEmailAddress) {
        this.orgErr.organisationEmailAddress = true;
      }
      if (!this.orgDetails.organisationPhoneNo) {
        this.orgErr.organisationPhoneNo = true;
      }
      if (this.orgDetails.organisationPhoneNo.length < 9) {
        this.orgErr.organisationPhoneNo = true;
        this.alertWarning("Phone number cannot be less than 9 digits.");
      }
      if (this.orgDetails.organisationPhoneNo.length > 15) {
        this.orgErr.organisationPhoneNo = true;
        this.alertWarning("Phone number cannot be more than 15 digits.");
      }
      if (!this.orgDetails.localDateFormat) {
        this.orgErr.localDateFormat = true;
      }
      if (!this.orgDetails.localDateTimeFormat) {
        this.orgErr.localDateTimeFormat = true;
      }
      if (!this.orgDetails.decimalMark) {
        this.orgErr.decimalMark = true;
      }

      if (
        this.orgDetails.organisationName &&
        this.orgDetails.organisationEmailAddress &&
        this.orgDetails.organisationPhoneNo &&
        this.orgDetails.organisationPhoneNo.length >= 9 &&
        this.orgDetails.organisationPhoneNo.length <= 15 &&
        this.orgDetails.localDateFormat &&
        this.orgDetails.localDateTimeFormat &&
        this.orgDetails.decimalMark
      ) {
        return true;
      }
    },
    clearOrgErr() {
      this.orgErr = {
        organisationName: false,
        organisationEmailAddress: false,
        organisationPhoneNo: false,
        localDateFormat: false,
        localDateTimeFormat: false,
        decimalMark: false,
      };
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getOrgDetails();
    this.getCurrency();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateOrgDeteails() {
      const OrgDeteails = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = OrgDeteails;
      return permissionsList.CanCreateOrganizationDetails;
    },
    userCanEditOrgDeteails() {
      const OrgDeteails = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = OrgDeteails;
      return permissionsList.CanEditOrganizationDetails;
    },
    userCanDeleteOrgDeteails() {
      const OrgDeteails = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = OrgDeteails;
      return permissionsList.CanDeleteOrganizationDetails;
    },
  },
});
</script>
